<template>
  <div :class="$style.home">
    <div :class="$style.header">
      <div :class="$style.icon">
        <i class="ri-emotion-line" />
      </div>
      <div :class="$style.right">
        <p :class="$style.title">模板页面</p>
        <p :class="$style.dec">
          自定义移动端模板页面，可用于自定义功能页、推广落地页、活动介绍页等。模板页面生成后，可在需要跳转的时候设置此模板页面。
        </p>
      </div>
    </div>
    <div :class="$style.container">
      <a-row :class="$style.head">
        <a-col span="4" :class="$style.title"> 页面列表 </a-col>
        <a-col span="20" :class="$style.btns">
          <BjButton v-permission="'terminal.mobile.template-create'" type="primary" @click="onAdd()">
            <i class="ri-add-line left" />
            新建页面
          </BjButton>
        </a-col>
      </a-row>
      <a-table :loading="loading" :columns="columns" :data-source="data" :pagination="false" row-key="id">
        <template #cover="item">
          <div :class="$style.cover">
            <img :src="item.cover.cover" />
          </div>
        </template>
        <template #status="item">
          <BjTag :type="item.status === 1 ? 'primary' : 'default'">{{ item.status === 1 ? '已发布' : '未发布' }}</BjTag>
        </template>
        <template #action="item">
          <bj-link v-permission="'terminal.mobile.template-edit'" type="primary" @click="onEdit(item)"> 编辑 </bj-link>
          <bj-link type="primary" @click="onPreview(item)"> 预览 </bj-link>
          <bj-link v-permission="'terminal.mobile.template-copy'" type="primary" @click="onCopy(item)"> 复制 </bj-link>
          <a-popconfirm placement="topLeft" title="确定要删除资讯吗？" @confirm="onDelete(item.id)">
            <bj-link v-permission="'terminal.mobile.template-delete'" type="danger"> 删除 </bj-link>
          </a-popconfirm>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { terminalTemplateService } from '@/service'

const service = new terminalTemplateService()

export default {
  name: 'Home',
  data() {
    return {
      loading: false,
      data: [],
      page: 1,
      total: 0,
      pageSize: 20,
    }
  },
  computed: {
    columns() {
      return [
        { title: '页面名称', dataIndex: 'title' },
        { title: '是否引用', dataIndex: 'is_refer', customRender: text => (text === 0 ? '否' : '是') },
        { title: '创建时间', dataIndex: 'created_at' },
        {
          title: '管理',
          width: 180,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.getList()
  },
  methods: {
    onAdd() {
      this.$router.push({
        name: 'terminalTemplateAdd',
      })
    },
    async getList() {
      try {
        const { data } = await service.getList({
          page: this.page,
          page_size: this.pageSize,
        })
        this.data = data.record
        this.total = data.total
      } catch (e) {}
    },
    async onCopy(item) {
      await service.copy({
        id: item.id,
      })
    },
    onEdit(item) {
      this.$store.commit('EDITOR', {
        page_type: 'template',
        id: item.id,
      })
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'editor',
      })
    },
    onPreview(item) {
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'frontPreview',
        query: {
          type: 'template',
          id: item.id,
        },
      })
    },
    async onDelete(id) {
      try {
        await service.delete({
          id: id,
        })
        this.page = 1
        this.getList()
        this.$message.success('删除成功')
      } catch (error) {}
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.home {
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 90px;
    background: #fff;

    .icon {
      width: 50px;
      height: 50px;
      margin-left: 20px;
      text-align: center;
      background: #fafafa;
      border-radius: 25px;

      i {
        display: inline-block;
        margin-top: 10px;
        color: #000;
        font-size: 20px;
      }
    }

    .dec {
      color: #5c5c5c;
      font-size: 12px;
    }

    .right {
      flex: 1;
      padding-left: 12px;
    }

    .title {
      margin: 12px 0 4px 0;
      color: #000;
      font-size: 16px;
    }
  }

  .container {
    margin-top: 20px;
    padding: 20px;
    background: #fff;
    min-height: calc(100vh - 262px);

    .head {
      line-height: 32px;
      margin-bottom: 20px;
    }

    .title {
      color: #000;
      font-size: 16px;
    }

    .btns {
      text-align: right;
    }
  }
}
</style>
